import { FC, useState, useEffect, useContext, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
var aesjs = require('aes-js');
import { logEvent } from 'firebase/analytics';
import { UserTraits } from '@segment/analytics-next';

import '../../../App.scss';
import AmountView from '../../../components/amount/AmountView';
import { analytics } from '../../../services/FirebaseService';
import { useOnMount, usePaymentMethods } from '../../../hooks';
import { SentryIO } from '../../../services/SentryIO';
import { FETCH_STATUS } from '../../../api/ApiClient';
import { stringToCents } from '../../../utils/Money';
import { NabyOrganization } from '../../../models/NabyAccount';
import { identifyUser } from './identifyUser';

import {
  getDeploymentSettings,
  getSecurePaymentThreshold,
  getOrganizationInfo,
} from '../../../services/FirestoreService';

import Context, {
  ActionTypes,
  QuickstartState,
} from '../../../components/Context';

interface Props {
  match: any;
}

const useQuery = () => new URLSearchParams(useLocation().search);

var key = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16];

export const EnterPaymentAmountScreen: FC<Props> = () => {
  const history = useHistory();
  const { dispatch } = useContext(Context);
  let query = useQuery();
  const location = useLocation();
  const fullUrl =
    window.location.origin +
    location.pathname +
    location.search +
    location.hash;

  const [deploymentSettings, setDeploymentSettings] = useState<{
    backToLifeTime: string;
    enabled: boolean;
  }>();

  const [loading, setLoading] = useState(false);
  const [organisation, setOrganization] = useState<NabyOrganization>();
  const [orgFetchStatus, setOrgFetchStatus] = useState(FETCH_STATUS.IDLE);

  const [id, setId] = useState<string>();
  const [organisationId, setOrganizationId] = useState();
  const [amount, setAmount] = useState('0');
  const [ref, setRef] = useState();
  const [paymentType, setPaymentType] = useState();

  const maxAmount = 1000000;
  const { stripeBankEnabled, stripeCardEnabled } = usePaymentMethods(
    organisation || {},
    stringToCents(amount) > maxAmount,
  );

  useOnMount(() => {
    const decryptQueryParams = () => {
      try {
        const encryptedBytes = aesjs.utils.hex.toBytes(query.get('s') || '');
        const aesCtr = new aesjs.ModeOfOperation.ctr(key);
        const decryptedBytes = aesCtr.decrypt(encryptedBytes);
        const decryptedText = aesjs.utils.utf8.fromBytes(decryptedBytes);
        return JSON.parse(decryptedText);
      } catch (error) {
        SentryIO.captureException(error, {
          extra: { context: 'Trying to decrypt payment query' },
        });
        history.replace('/pay/account-not-found');
        return null;
      }
    };

    const decryptedParams = decryptQueryParams();
    if (decryptedParams) {
      setId(decryptedParams.id);
      setOrganizationId(decryptedParams.organisationId);
      setAmount(decryptedParams.amount);
      setRef(decryptedParams.ref);
      setPaymentType(decryptedParams.paymentType);

      if (decryptedParams.amount) {
        logEvent(analytics, 'screen_view', {
          firebase_screen: 'Pay - prefilled',
          firebase_screen_class: 'Pay - prefilled',
        });
      } else {
        logEvent(analytics, 'screen_view', {
          firebase_screen: 'Pay',
          firebase_screen_class: 'Pay',
        });
      }
    }
  });

  useOnMount(() => {
    sessionStorage.setItem('entryPointUrl', fullUrl);
  });

  useEffect(() => {
    if (!organisationId) {
      return;
    }

    fetchOrganizationDetails();

    const state: Partial<QuickstartState> = {
      userId: id,
    };

    dispatch({ type: ActionTypes.setState, state });
  }, [organisationId]);

  // If there is no any payment method, display an error
  useEffect(() => {
    if (
      organisation &&
      orgFetchStatus === FETCH_STATUS.SUCCESS &&
      !stripeBankEnabled &&
      !stripeCardEnabled
    ) {
      history.push('/pay/merchant-offline', {
        userId: id,
        orgDisplayName: organisation.orgname,
        orgProfilePhotoUrl: organisation.profilePhoto,
      });
    }
  }, [orgFetchStatus, stripeBankEnabled, stripeCardEnabled]);

  useEffect(() => {
    if (typeof id === 'string') {
      SentryIO.setUser({ id });
    }

    if (typeof organisationId === 'string') {
      SentryIO.setExtra('Organisation ID', organisationId);
    }
  }, [id, organisationId]);

  const fetchOrganizationDetails = async () => {
    setLoading(true);
    setOrgFetchStatus(FETCH_STATUS.LOADING);

    try {
      const deploymentSettingsResponse = await getDeploymentSettings();

      if (
        typeof deploymentSettingsResponse.data.backToLifeTime === 'string' &&
        typeof deploymentSettingsResponse.data.enabled === 'boolean'
      ) {
        setDeploymentSettings({
          enabled: deploymentSettingsResponse.data.enabled,
          backToLifeTime: deploymentSettingsResponse.data.backToLifeTime,
        });
      }

      const securePaymentThreshold = await getSecurePaymentThreshold();

      if (!securePaymentThreshold.data) {
        throw new Error('Secure payment threshold data not found');
      }

      dispatch({
        type: ActionTypes.setSecurePaymentThreshold,
        state: {
          securePaymentThreshold: securePaymentThreshold.data,
        },
      });

      if (
        deploymentSettingsResponse.data?.enabled === false &&
        organisationId
      ) {
        const res = await getOrganizationInfo(organisationId);

        if (!res.result) {
          throw new Error(res.data);
        }

        const organisation = res.data as NabyOrganization;
        setOrganization(organisation);

        dispatch({
          type: ActionTypes.setState,
          state: { organisation },
        });

        /**
         * Identify user with traits
         */
        if (id) {
          identifyUser(id, {
            isAccountBlocked:
              organisation.private.businessPermissions.isBlocked,
          });
        }

        if (
          organisation.private.businessPermissions &&
          organisation.private.businessPermissions.isBlocked
        ) {
          //Navigate to the notification screen, if merchant is blocked
          history.push({
            pathname: '/pay/merchant-blocked',
            state: {
              orgDisplayName: organisation.orgname,
              orgProfilePhotoUrl: organisation.profilePhoto,
            },
          });
        }

        if (
          organisation.private.stripe &&
          organisation.private.stripe.status !== 'complete'
        ) {
          //Navigate to the notification screen, if payments are not allowed
          history.push({
            pathname: '/pay/merchant-paused',
            state: {
              orgDisplayName: organisation.orgname,
              orgProfilePhotoUrl: organisation.profilePhoto,
            },
          });
        }
      }

      setOrgFetchStatus(FETCH_STATUS.SUCCESS);
    } catch (error) {
      alert(error);
      SentryIO.captureException(error, {
        extra: {
          context: 'Payment amount. Fetching data failed',
        },
      });
      setOrgFetchStatus(FETCH_STATUS.ERROR);
    } finally {
      setLoading(false);
    }
  };

  return (
    <AmountView
      amount={amount}
      refNum={ref}
      loading={loading}
      paymentType={paymentType}
      onRetry={fetchOrganizationDetails}
      backToLifeTime={deploymentSettings?.backToLifeTime}
      deploymentEnabled={deploymentSettings?.enabled}
    />
  );
};
