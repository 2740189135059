import { doc, getDoc } from 'firebase/firestore';

import { db } from './FirebaseService';
import { NabyOrganization } from '../models/NabyAccount';
import {
  SecurePaymentThreshold,
  TDeploymentSettings,
} from '../models/SystemSettings';

export const getUserAccountInfo = (id: string) =>
  getDoc(doc(db, 'NabyAccount', `${id}`, 'publicData'))
    .then((doc) => {
      if (doc.exists()) {
        return { result: true, data: doc.data() };
      } else {
        throw new Error('No user found');
      }
    })
    .catch((err) => ({
      result: false,
      err,
    }));

export const getOrganizationInfo = async (id: string): Promise<any> => {
  try {
    let responseData: any;

    const docPublicRef = doc(db, 'NabyOrganization', `${id}`);
    const docPrivateRef = doc(
      db,
      'NabyOrganization',
      `${id}`,
      'PRIVATE',
      'data',
    );

    const docPublic = await getDoc(docPublicRef);
    const docPrivate = await getDoc(docPrivateRef);

    if (docPublic.exists()) {
      responseData = {
        result: true,
        data: { id, ...docPublic.data() },
      };
    } else {
      throw new Error('No user found');
    }

    if (docPrivate.exists() && responseData) {
      const privateCompanyData = docPrivate.data();
      responseData.data.private = {
        companyName: privateCompanyData.companyDetails.companyName,
        companyRegNum: privateCompanyData.companyDetails.companyRegNum,
        customersVisiblility:
          privateCompanyData.companyDetails.customersVisiblility,
        companyAddress:
          privateCompanyData.companyDetails.companyAddress.fullAddress,
        companyZip: privateCompanyData.companyDetails.companyAddress.zip,
        stripe:
          typeof privateCompanyData.stripe === 'object'
            ? privateCompanyData.stripe
            : null,
        businessPermissions: privateCompanyData.businessPermissions,
      } as NabyOrganization['private'];
    }

    return responseData;
  } catch (error) {
    return {
      result: false,
      data: error,
    };
  }
};

export const getDeploymentSettings = async (): Promise<{
  result: boolean;
  data: TDeploymentSettings;
}> => {
  const docRef = doc(db, 'SystemSettings', `deployment`);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return {
      result: true,
      data: docSnap.data() as TDeploymentSettings,
    };
  } else {
    throw new Error('No deployment settings found');
  }
};

export const getSecurePaymentThreshold = async (): Promise<{
  result: boolean;
  data: SecurePaymentThreshold;
}> => {
  const docRef = doc(db, 'SystemSettings', 'securePaymentThreshold');
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return {
      result: true,
      data: docSnap.data() as SecurePaymentThreshold,
    };
  } else {
    throw new Error('Extra data secure threshold settings not found');
  }
};
