import {
  FormControlLabel as MuiFormControlLabel,
  FormControlLabelProps,
} from '@mui/material';

type TFormControlLabelProps = {
  className?: string;
  control: JSX.Element;
  label?: any;
  labelPlacement?: FormControlLabelProps['labelPlacement'];
  style?: React.CSSProperties;
};

const sx: FormControlLabelProps['sx'] = {
  '&.MuiFormControlLabel-root .MuiFormControlLabel-label': {
    color: '#7A7A7A',
    fontFamily: 'Montserrat',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '20px',
    textTransform: 'none',
  },
};

const FormControlLabel = ({
  control,
  label,
  labelPlacement,
  style,
  className,
}: TFormControlLabelProps) => {
  return (
    <MuiFormControlLabel
      className={className}
      control={control}
      label={label}
      labelPlacement={labelPlacement}
      style={style}
      sx={sx}
    />
  );
};

export default FormControlLabel;
