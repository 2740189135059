import Lottie from 'lottie-react';

import './EmailVerification.scss';
import alertAnimation from '../../assets/lottie/alert-circle-outline.json';
import Button from '../common/Button';

export const EmailVerificationError = ({
  onVerifyEmail,
  onContactUs,
}: {
  onVerifyEmail: () => void;
  onContactUs: () => void;
}) => {
  return (
    <div className="email-verification__content email-verification__success">
      <Lottie
        animationData={alertAnimation}
        className="email-verification__loader"
        loop={false}
      />
      <h1 className="email-verification__heading">Oops...</h1>

      <p className="email-verification__description">
        There was a problem verifying your email address, please try again.
      </p>

      <div className="email-verification__btn-container">
        <Button label="Try again" onClick={onVerifyEmail} />
        <Button label="Contact us" onClick={onContactUs} btnType="outline" />
      </div>
    </div>
  );
};
