import { FC, ReactNode, memo } from 'react';
import { useHistory } from 'react-router-dom';
import './Header.scss';
import back from '../../../assets/images/back.svg';

type Props = {
  noBack?: boolean;
  style?: React.CSSProperties;
  className?: string;
  children: ReactNode;
};

const Header: FC<Props> = memo(({ noBack, children, style, className }) => {
  const history = useHistory();
  return (
    <div style={style} className={className}>
      {!noBack && <img src={back} onClick={history.goBack} />}
      <h1>{children}</h1>
    </div>
  );
});

export default Header;
