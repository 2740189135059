import { FC, memo } from 'react';
import { useHistory } from 'react-router';
import { logEvent } from 'firebase/analytics';

import ReceiptContent from '../../components/receipt-content/ReceiptContent';
import { analytics } from '../../services/FirebaseService';

interface Props {
  match: any;
  location: any;
}

const ReceiptScreen: FC<Props> = memo(({ match, location }: Props) => {
  const history = useHistory();
  const organisation = location.state.organisation;
  const transaction = location.state.transaction;

  console.log('ReceiptScreen', organisation, transaction);

  logEvent(analytics, 'screen_view', {
    firebase_screen: 'Pay - receipt',
    firebase_screen_class: 'Pay - receipt',
  });

  return (
    <ReceiptContent
      initialEmail={transaction.email}
      onPress={(email) =>
        history.push('/receiptConfirm', {
          organisation,
          transaction,
          email,
        })
      }
    />
  );
});

export default ReceiptScreen;
