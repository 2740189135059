import { formatValue } from 'react-currency-input-field';
import getSymbolFromCurrency from 'currency-symbol-map';

const moneyFormat = (value: string | undefined, currency: string = 'gbp') =>
  formatValue({
    value: value || '0',
    prefix: getSymbolFromCurrency(currency),
    decimalScale: 2,
  });

const stringToCents = (num: string) => Math.round(Number.parseFloat(num) * 100);

export { moneyFormat, stringToCents };
