import { FC, memo } from 'react';
import AnonUser from '../../../assets/images/anon.png';

import './UserAvatar.scss'

type Props = { image?: string };

const UserAvatar: FC<Props> = memo(({ image }) => (
  <img className="avatar" src={image || AnonUser} />
));

export default UserAvatar;
