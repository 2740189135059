import { segmentClient } from '../configs/segmentConfig';

export class AnalyticsService {
  static sendEventToSegment = async (
    ...params: Parameters<typeof segmentClient.track>
  ) => {
    return await segmentClient.track(
      params[0],
      params[1],
      params[2],
      params[3],
    );
  };
}
