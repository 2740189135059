import * as EmailValidator from 'email-validator';

export class ValidationUtil {
  static isEmailValid = (email: string) => {
    try {
      return EmailValidator.validate(email);
    } catch (_error) {
      return false;
    }
  };
}
