import { Switch as MuiSwitch, SwitchProps } from '@mui/material';

type TSwitchProps = {
  name?: string;
  checked?: boolean;
  style?: React.CSSProperties;
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => void;
};

const sx: SwitchProps['sx'] = {
  '&.MuiSwitch-root': {
    height: 'auto',
  },

  '&.MuiSwitch-root .MuiSwitch-switchBase': {
    marginTop: '4px',
    color: '#FFF',
    left: '4px',
  },

  '&.MuiSwitch-root .Mui-checked': {
    color: '#FFF',
    transform: 'translate(16px)',
  },

  '&.MuiSwitch-root .MuiSwitch-track': {
    bgcolor: '#BBC2CA',
    opacity: 1,
    height: '18px',
    borderRadius: '100px',
  },

  '&.MuiSwitch-root .Mui-checked + .MuiSwitch-track': {
    opacity: 1,
    bgcolor: '#FC4C02',
  },

  '&.MuiSwitch-root .MuiSwitch-thumb': {
    height: '16px',
    width: '16px',
    borderRadius: '100px',
    boxShadow: 'none',
  },
};

const Switch = ({ checked, onChange, name, style }: TSwitchProps) => {
  return (
    <MuiSwitch
      checked={checked}
      disableRipple
      name={name}
      onChange={onChange}
      sx={sx}
      style={style}
    />
  );
};

export default Switch;
