import { FC, memo, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import Lottie from 'lottie-react';

import './AmountView.scss';
import Body from '../../widgets/layout/Body';
import BottomNavigationBar from '../navigation/BottomNavigationBar';
import CompanyInfo from '../company/CompanyInfo';
import Container from '../../widgets/layout/Container';
import Context, { ActionTypes } from '../Context';
import DataField from '../common/DataField';
import PrimaryButton from '../common/Button';
import UserAvatar from '../common/UserAvatar';
import loaderAnimation from '../../assets/lottie/loader.json';
import translations from '../../assets/translations';
import { DeploymentNotificationContent } from '../maintenance/DeploymentNotificationContent';
import { TPaymentType } from '../../models/UserMetadata';

type Props = {
  amount: string;
  refNum?: string;
  loading?: boolean;
  paymentType?: TPaymentType;
  backToLifeTime?: string;
  onRetry: () => void;
  deploymentEnabled?: boolean;
};

const Schema = Yup.object().shape({
  amount: Yup.number()
    .min(1, 'Minimum payment is £1.')
    .required()
    .label('Amount'),
  refNum: Yup.string(),
});

const AmountView: FC<Props> = memo(
  ({
    amount,
    backToLifeTime,
    deploymentEnabled,
    loading,
    onRetry,
    paymentType,
    refNum,
  }: Props) => {
    const { userId, organisation, dispatch, systemSettings } =
      useContext(Context);
    const history = useHistory();
    const isInput = !amount;
    const isAmountPrefilled = !!amount;

    if (loading) {
      return (
        <Container>
          <Body className="amount-view__loading">
            <Lottie
              animationData={loaderAnimation}
              className="email-verification__loader"
              loop
            />
          </Body>

          <BottomNavigationBar />
        </Container>
      );
    }

    if (deploymentEnabled) {
      return (
        <DeploymentNotificationContent
          backToLifeTime={backToLifeTime || ''}
          onRetry={onRetry}
        />
      );
    }

    return (
      <Container>
        <Body>
          <UserAvatar image={organisation?.profilePhoto} />
          {organisation?.private.customersVisiblility ? (
            <CompanyInfo
              companyName={organisation.private.companyName}
              companyAddress={organisation.private.companyAddress}
              companyZip={organisation.private.companyZip}
              companyRegNum={organisation.private.companyRegNum}
            />
          ) : null}

          <DataField title={translations.payTo} value={organisation?.orgname} />
          <Formik
            validationSchema={Schema}
            initialValues={{
              amount: amount?.toString() || '',
              refNum: refNum || '',
            }}
            onSubmit={({ amount, refNum }) => {
              try {
                const date = moment().unix();
                const transaction = {
                  amount,
                  refNum,
                  date,
                };
                dispatch({
                  type: ActionTypes.setState,
                  state: { transaction, isAmountPrefilled },
                });
                history.push('/pay/providers', {
                  userId,
                  organisation,
                  transaction,
                  paymentType,
                  systemSettings,
                });
              } catch (error: any) {
                alert(error.toString());
              }
            }}>
            {({
              values,
              errors,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              isSubmitting,
            }) => (
              <Form className="flex-form" onSubmit={handleSubmit}>
                <div>
                  <DataField
                    name="amount"
                    isAmount
                    isInput={isInput}
                    title={
                      isInput ? translations.enterAmount : translations.amount
                    }
                    value={values.amount}
                    error={errors.amount}
                    handleChange={(value: string) =>
                      setFieldValue('amount', value)
                    }
                    handleBlur={handleBlur}
                  />
                  {(isInput || !!values.refNum) && (
                    <DataField
                      name="refNum"
                      isInput={isInput}
                      isOptional={isInput}
                      title={isInput ? translations.ref : translations.refInv}
                      value={values.refNum}
                      error={errors.refNum}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                    />
                  )}
                </div>
                <PrimaryButton
                  isLoading={isSubmitting || loading}
                  type="submit"
                  label={translations.payNow}
                />
              </Form>
            )}
          </Formik>
        </Body>

        <BottomNavigationBar />
      </Container>
    );
  },
);

export default AmountView;
