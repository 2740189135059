import './styles.scss';
import { AlertPresenter } from '../../../components';
import { ReactComponent as UnhappyFace } from '../../../assets/images/ic-unhappy.svg';

export const AccountNotFoundScreen = () => {
  return (
    <AlertPresenter
      title="Oops"
      mainMessage="We can’t find an account for the merchant you are trying to pay. Please reopen the link or scan the QR code to try again."
      renderImage={() => <UnhappyFace className="image" />}
      secondaryMessage="If there is a problem, please contact the merchant."
    />
  );
};
