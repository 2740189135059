import { FC, memo, useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import {
  useStripe,
  PaymentRequestButtonElement,
} from '@stripe/react-stripe-js';

import {
  PaymentIntent,
  PaymentRequest,
  PaymentRequestPaymentMethodEvent,
} from '@stripe/stripe-js';

import './ApplePayment.scss';
import { stringToCents } from '../../utils/Money';
import StripeApi from '../../api/StripeApi';
import { TPaymentType } from '../../models/UserMetadata';
import { SentryIO } from '../../services/SentryIO';

type Props = {
  disabled?: boolean;
  paymentFields: any;
  paymentRequestParam: any;
  isAmountPrefilled?: boolean;
  paymentType?: TPaymentType;
};

const ApplePayment: FC<Props> = memo(
  ({
    disabled,
    paymentFields,
    paymentRequestParam,
    isAmountPrefilled,
    paymentType,
  }) => {
    const history = useHistory();
    const stripe = useStripe();
    if (!stripe) {
      return <div></div>;
    }
    const [paymentRequest, setPaymentRequest] = useState<PaymentRequest>();
    const { userId, organisation, transaction } = paymentFields;
    const [paymentIntent, setPaymentIntent] = useState<PaymentIntent>();

    const amount = stringToCents(transaction.amount);

    const makePayment = async (ev: PaymentRequestPaymentMethodEvent) => {
      try {
        const paymentIntentRes = (
          await StripeApi.proccessPayment({
            userId,
            organisationId: organisation?.id || '',
            amount,
            currency: 'gbp',
            date: transaction.date,
            refNum: transaction.refNum,
            paymentMethodId: ev.paymentMethod.id,
            country: ev.paymentMethod.card?.country || 'undefined',
            isCardPremium: true,
            isAmountPrefilled: isAmountPrefilled,
            paymentType: paymentType,
          })
        ).data;

        setPaymentIntent(paymentIntentRes);

        const onSucesss = () => {
          history.push('/pay/success', {
            organisation,
            transaction: {
              ...transaction,
              id: paymentIntentRes?.id,
              clientSecret: paymentIntentRes?.client_secret,
            },
          });
        };

        if (
          paymentIntentRes.status === 'requires_action' &&
          paymentIntentRes.client_secret
        ) {
          const { paymentIntent, error } = await stripe.confirmCardPayment(
            paymentIntentRes.client_secret,
          );
          if (error) {
            throw new Error(error.message);
          }
          if (paymentIntent?.status === 'succeeded') {
            onSucesss();
            ev.complete('success');
          } else {
            throw new Error('Something went wrong');
          }
        } else if (paymentIntentRes.status === 'succeeded') {
          onSucesss();
          ev.complete('success');
        } else {
          throw new Error('Something went wrong');
        }
      } catch (error) {
        ev.complete('fail');
        history.push('/pay/failure', {
          error:
            error instanceof Error ? error.message : 'Something went wrong!',
          userId,
          organisation,
          transaction: {
            ...transaction,
            id: paymentIntent?.id,
            clientSecret: paymentIntent?.client_secret,
          },
        });
      }
    };

    useEffect(() => {
      (async () => {
        try {
          if (stripe && !paymentRequest) {
            if (paymentRequestParam) {
              paymentRequestParam.on('paymentmethod', makePayment);
              setPaymentRequest(paymentRequestParam);
            } else {
              const pr = stripe.paymentRequest({
                country: 'GB',
                currency: 'gbp',
                total: { label: 'NabyPay', amount },
                requestPayerName: true,
                requestPayerEmail: true,
              });
              // Check the availability of the Payment Request API first.
              const result = await pr.canMakePayment();
              if (result) {
                pr.on('paymentmethod', makePayment);
                setPaymentRequest(pr);
              }
            }
          }
        } catch (error) {
          SentryIO.captureException(error, {
            extra: {
              context: 'Attempting to initialise wallet payment',
            },
          });
        }
      })();

      return () => {
        if (paymentRequest) {
          paymentRequest.off('paymentmethod', makePayment);
        }
      };
    }, [paymentRequest]);

    if (paymentRequest) {
      return (
        <div
          className="PayButton"
          style={{ pointerEvents: disabled ? 'none' : 'auto' }}>
          <PaymentRequestButtonElement
            options={{
              paymentRequest,
              style: { paymentRequestButton: { height: '60px' } },
            }}
          />
        </div>
      );
    }

    return <div></div>;
  },
);

export default ApplePayment;
