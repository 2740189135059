import { FC, useEffect } from 'react';
import { logEvent } from 'firebase/analytics';

import HelpImproveContent from '../../components/feedback/HelpImproveContent';
import { analytics } from '../../services/FirebaseService';

interface Props {
  history: any;
  location: any;
}

const HelpImprove: FC<Props> = ({ history, location }) => {
  useEffect(() => {
    logEvent(analytics, 'screen_view', {
      firebase_screen: 'Feedback request',
      firebase_screen_class: 'Feedback request',
    });
  }, []);

  return <HelpImproveContent />;
};

export default HelpImprove;
