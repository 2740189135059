import { FC, memo, useEffect } from 'react';
import moment from 'moment';
import { logEvent } from 'firebase/analytics';

import { API } from '../../api/ApiClient';
import ReceiptConfirmContent from '../../components/receipt-confirm-content/ReceiptConfirmContent';
import { NabyOrganization } from '../../models/NabyAccount';
import { moneyFormat } from '../../utils/Money';
import { analytics } from '../../services/FirebaseService';
import { SentryIO } from '../../services/SentryIO';
import { or } from 'firebase/firestore';

interface Props {
  match: any;
  location: any;
}

const ReceiptConfirmScreen: FC<Props> = memo(({ match, location }: Props) => {
  const email: string = location.state?.email;
  const organisation: NabyOrganization = location.state?.organisation ?? {};
  const transaction: {
    id: string;
    clientSecret: string | undefined;
    amount: string;
    refNum: string;
    date: number;
  } = location.state?.transaction ?? {};

  const sendEmail = async () => {
    logEvent(analytics, 'screen_view', {
      firebase_screen: 'Pay - success',
      firebase_screen_class: 'Pay - success',
    });

    try {
      if (!transaction.id) {
        throw new Error('Transaction ID not found');
      }

      await API.sendReceiptEmail({
        method: transaction.id,
        orgname: organisation.orgname,
        amount: moneyFormat(transaction.amount),
        date: moment.unix(transaction.date).format('DD/MM/YY HH:mm'),
        email: email,
        image: organisation.profilePhoto,
        refNum: transaction.refNum,
      });
    } catch (error) {
      SentryIO.captureException(error, {
        extra: {
          context: 'Attempting to send receipt email',
          transactionId: transaction.id,
          orgname: organisation.orgname,
          amount: transaction.amount,
          email: email,
        },
      });
      alert(error);
    }
  };

  useEffect(() => {
    sendEmail();
  }, []);

  return (
    <ReceiptConfirmContent
      orgname={organisation.orgname}
      email={email}
      onPress={sendEmail}
    />
  );
});

export default ReceiptConfirmScreen;
