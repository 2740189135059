import { useHistory } from 'react-router-dom';

import '../../../components/alerts/AlertPresenter/styles.scss';
import './styles.scss';
import UserAvatar from '../../../components/common/UserAvatar';
import { AlertPresenter } from '../../../components';
import { AnalyticsEvent } from '../../../types/analyticsTypes';
import { AnalyticsService } from '../../../services';
import { NoPaymentMethodFoundHistoryState } from './types';
import { useOnMount } from '../../../hooks';

export const NoPaymentMethodFoundScreen = () => {
  const history = useHistory<NoPaymentMethodFoundHistoryState>();
  const location = history.location;
  const { orgDisplayName, orgProfilePhotoUrl } = location.state;

  useOnMount(() => {
    AnalyticsService.sendEventToSegment(AnalyticsEvent.MerchantAccountError, {
      errorType: 'Payment Method',
    });
  });

  return (
    <AlertPresenter
      title="Merchant offline"
      renderMessage={() => (
        <p className="common-error__main-message">
          <span className="no-payment-method-found__main-message--bold">
            {orgDisplayName}
          </span>{' '}
          is currently not accepting payments on this link. We have just sent
          them a reminder to help them resolve this.
        </p>
      )}
      renderImage={() => <UserAvatar image={orgProfilePhotoUrl} />}
      secondaryMessage="If there is a problem, please contact the merchant directly."
    />
  );
};
