import Body from '../../widgets/layout/Body';
import Container from '../../widgets/layout/Container';
import BottomNavigationBar from '../navigation/BottomNavigationBar';

import './PaymentResult.scss';

const PaymentResultCompleted = () => {
  return (
    <Container>
      <Body>
        <h1 className="payment-result-heading">Payment successful</h1>
        <p className="payment-result-details">
          Your payment completed successfully in a new tab, you can close this
          tab.
        </p>
      </Body>
      <BottomNavigationBar />
    </Container>
  );
};

export { PaymentResultCompleted };
