import './styles.scss';
import Body from '../../../widgets/layout/Body';
import Button from '../../common/Button';
import Container from '../../../widgets/layout/Container';
import { AlertPresenterProps } from './types';

export const AlertPresenter = ({
  buttonTitle,
  buttonType = 'primary',
  mainMessage,
  onButtonPress,
  renderImage,
  renderMessage,
  secondaryMessage,
  title,
}: AlertPresenterProps) => {
  return (
    <Container>
      <Body>
        <div className="common-error__top-content">
          {/** Image */}
          {renderImage ? renderImage() : null}

          {/** Title */}
          <h1 className="common-error__title">{title}</h1>

          {/** Message */}
          {mainMessage ? (
            <p className="common-error__main-message">{mainMessage}</p>
          ) : null}

          {/** Custome message */}
          {renderMessage && !mainMessage ? renderMessage() : null}
        </div>

        <div className="common-error__bottom-content">
          {/** Secondary text */}
          {secondaryMessage ? (
            <p className="common-error__bottom-text">
              If there is a problem, please contact the merchant directly.
            </p>
          ) : null}

          {/** Button */}
          {buttonTitle && onButtonPress ? (
            <Button
              btnType={buttonType}
              label={buttonTitle}
              onClick={onButtonPress}
            />
          ) : null}
        </div>
      </Body>
    </Container>
  );
};
