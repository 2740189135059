import React from 'react';
import { AddressElement } from '@stripe/react-stripe-js';
import {
  CreatePaymentMethodData,
  Stripe,
  StripeElements,
} from '@stripe/stripe-js';

import './CheckoutForm.scss';
import CardSection from './CardSection';
import Button from '../../components/common/Button';
import Container from '../../widgets/layout/Container';
import Body from '../../widgets/layout/Body';
import Header from '../common/Header';
import translations from '../../assets/translations';
import DataField from '../common/DataField';
import StripeApi from '../../api/StripeApi';
import { stringToCents } from '../../utils/Money';
import Switch from '../common/Switch';
import FormControlLabel from '../common/FormControlLabel';
import { TPaymentType } from '../../models/UserMetadata';
import { ValidationUtil } from '../../utils';
import { SecurePaymentThreshold } from '../../models/SystemSettings';
import { NabyOrganization } from '../../models/NabyAccount';
import EnvVars from '../../services/EnvVars';
import { openInTheSameTab } from '../../utils/utils';
import { StorageKeys } from '../../types/storageKeys';

interface Props {
  location: any;
  stripe: Stripe | null;
  elements: StripeElements | null;
  paymentSuccess: (transaction: any) => any;
  paymentFailed: (error: string) => any;
  isAmountPrefilled: boolean;
  paymentType?: TPaymentType;
  organisation: NabyOrganization;
  transaction: object;
  userId: string;
  systemSettings: object;
}

export class CheckoutForm extends React.Component<Props> {
  state = {
    isCardPremium: false,
    loading: false,
    name: {
      value: '',
      errorMsg: null,
    },
    cardNumber: {
      isComplete: false,
      errorMsg: null,
    },
    cardExpiry: {
      isComplete: false,
      errorMsg: null,
    },
    cardCvc: {
      isComplete: false,
      errorMsg: null,
    },
    email: {
      value: '',
      isValid: true,
      isTouched: false,
    },
    address: {
      isComplete: false,
    },
  };

  handleCardElementUpdate = ({
    elementName,
    isComplete,
    errorMsg,
  }: {
    elementName: 'cardNumber' | 'cardExpiry' | 'cardCvc';
    isComplete: boolean;
    errorMsg: string | null;
  }) => {
    if (elementName === 'cardNumber') {
      this.setState({
        cardNumber: {
          isComplete,
          errorMsg,
        },
      });
      return;
    }

    if (elementName === 'cardExpiry') {
      this.setState({
        cardExpiry: {
          isComplete,
          errorMsg,
        },
      });
      return;
    }

    if (elementName === 'cardCvc') {
      this.setState({
        cardCvc: {
          isComplete,
          errorMsg,
        },
      });
      return;
    }
  };

  handleEmailValueChange = (newValue: string) => {
    this.setState({ emailValue: newValue });
  };

  handleSubmit = async (event: any) => {
    event.preventDefault();

    const { location, stripe, elements, isAmountPrefilled, paymentType } =
      this.props;

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make  sure to disable form submission until Stripe.js has loaded.
      this.setState({ loading: false });
      return;
    }

    const userId = location.state.userId;
    const organisation = location.state.organisation;
    const transaction = location.state.transaction;
    const amount = stringToCents(transaction.amount);

    try {
      this.setState({ loading: true });

      const card = elements.getElement('cardNumber');

      if (!card) {
        throw new Error('Payment by card. No banking card');
      }

      const addressElement = elements.getElement('address');
      const addressElementValue = await addressElement?.getValue();

      const createPaymentMethodParams: CreatePaymentMethodData = {
        type: 'card',
        card,
        billing_details: {},
      };

      if (addressElementValue?.value.address) {
        createPaymentMethodParams.billing_details = {
          ...createPaymentMethodParams.billing_details,
          address: {
            city: addressElementValue.value.address.city,
            state: addressElementValue.value.address.state,
            country: addressElementValue.value.address.country,
            line1: addressElementValue.value.address.line1,
            line2: addressElementValue.value.address.line2 || undefined,
            postal_code: addressElementValue.value.address.postal_code,
          },
        };
      }

      createPaymentMethodParams.billing_details = {
        ...createPaymentMethodParams.billing_details,
        name: addressElementValue?.value.name || this.state.name.value,
      };

      if (addressElementValue?.value.phone) {
        createPaymentMethodParams.billing_details = {
          ...createPaymentMethodParams.billing_details,
          phone: addressElementValue.value.phone,
        };
      }

      const paymentMethodRes = await stripe.createPaymentMethod(
        createPaymentMethodParams,
      );

      const newPaymentMethod = paymentMethodRes.paymentMethod;

      if (paymentMethodRes.error || !newPaymentMethod) {
        throw new Error(paymentMethodRes.error.message);
      }

      if (!newPaymentMethod.card?.country) {
        throw new Error('Payment method country not found');
      }

      const createdPaymentMethodId = newPaymentMethod.id;

      const handleCardPaymentParams: Parameters<
        typeof StripeApi.handleCardPayment
      >[0] = {
        amount: amount,
        country: newPaymentMethod.card.country,
        createdPaymentMethodId: createdPaymentMethodId,
        currency: 'gbp',
        date: transaction.date,
        email: this.state.email.value,
        fullName: addressElementValue?.value.name || this.state.name.value,
        isAmountPrefilled: isAmountPrefilled,
        isCardPremium: this.state.isCardPremium,
        organizationId: organisation.id,
        paymentType: paymentType,
        refNum: transaction.refNum,
        userId: userId,
      };

      if (addressElementValue && addressElementValue.value.address) {
        handleCardPaymentParams.address = addressElementValue.value.address;
      }

      if (addressElementValue && addressElementValue.value.phone) {
        handleCardPaymentParams.phone = addressElementValue.value.phone;
      }

      const paymentIntentRes = await StripeApi.handleCardPayment(
        handleCardPaymentParams,
      );

      if (paymentIntentRes.data?.status === 'succeeded') {
        console.log('Payment by card. Confirmation succeeded');
        console.log('Props', {
          ...transaction,
          id: paymentIntentRes.data?.id,
          clientSecret: paymentIntentRes.data?.client_secret,
          email: this.state.email.value,
        });
        this.props.paymentSuccess({
          ...transaction,
          id: paymentIntentRes.data?.id,
          clientSecret: paymentIntentRes.data?.client_secret,
          email: this.state.email.value,
        });
      } else if (
        paymentIntentRes.data?.status === 'requires_action' &&
        paymentIntentRes.data?.next_action?.type === 'redirect_to_url' &&
        paymentIntentRes.data?.next_action?.redirect_to_url?.url
      ) {
        // Store data to session storage
        sessionStorage.setItem(
          StorageKeys.ThreeDSecureRedirectData,
          JSON.stringify({
            organisation: this.props.organisation,
            transaction: this.props.transaction,
            userId: this.props.userId,
            systemSettings: this.props.systemSettings,
          }),
        );

        // Redirect user to pass 3D secure
        openInTheSameTab(
          paymentIntentRes.data?.next_action?.redirect_to_url?.url,
        );
      } else if (paymentIntentRes.data.error && paymentIntentRes.data.message) {
        throw new Error(paymentIntentRes.data.message);
      } else {
        throw new Error('Payment by card. Confirmation failed');
      }
    } catch (error: any) {
      if (error instanceof Error) {
        this.props.paymentFailed(error.message);
      } else {
        this.props.paymentFailed('Something went wrong!');
      }
    } finally {
      this.setState({ loading: false });
    }
  };

  render() {
    const { location } = this.props;
    const systemSettings = location.state.systemSettings;
    const securePaymentThreshold: SecurePaymentThreshold =
      systemSettings.securePaymentThreshold;

    const organisation: NabyOrganization = location.state.organisation;
    const transaction = location.state.transaction;
    const amount = +transaction.amount;
    const isBusinessTrusted = organisation.private.stripe.isTrusted;

    const addressCaptureAmountThreshold = isBusinessTrusted
      ? securePaymentThreshold.businessIsTrusted.true
          .addressCaptureAmountThreshold
      : securePaymentThreshold.businessIsTrusted.false
          .addressCaptureAmountThreshold;

    const phoneNumberCaptureAmountThreshold = isBusinessTrusted
      ? securePaymentThreshold.businessIsTrusted.true
          .phoneNumberCaptureAmountThreshold
      : securePaymentThreshold.businessIsTrusted.false
          .phoneNumberCaptureAmountThreshold;

    const isAddressElementDisplayed =
      securePaymentThreshold.enabled && amount >= addressCaptureAmountThreshold;

    const isPhoneNumberFieldDisplayed =
      isAddressElementDisplayed && amount >= phoneNumberCaptureAmountThreshold;

    const isSubmitButtonDisabled = [
      !this.props.stripe,
      !!this.state.name.errorMsg,
      !this.state.name.value,
      !!this.state.cardNumber.errorMsg,
      !this.state.cardNumber.isComplete,
      !!this.state.cardExpiry.errorMsg,
      !this.state.cardExpiry.isComplete,
      !!this.state.cardCvc.errorMsg,
      !this.state.cardCvc.isComplete,
      !this.state.email.value,
      !this.state.email.isTouched,
      !this.state.email.isValid,
      isAddressElementDisplayed && !this.state.address.isComplete,
    ].includes(true);

    return (
      <Container>
        <Body>
          <Header>{translations['details.cardDetails']}</Header>
          <form className="card-form" onSubmit={this.handleSubmit}>
            {/** Name on card */}
            <DataField
              autoCapitalize="words"
              isInput
              error={this.state.name.errorMsg || undefined}
              title={translations.nameCard}
              value={this.state.name.value}
              //@ts-ignore
              handleChange={(event: any) => {
                this.setState({
                  name: {
                    value: event.target.value,
                    errorMsg:
                      event.target.value.length === 0
                        ? 'Name is required'
                        : null,
                  },
                });
              }}
              placeholder="Your Name"
            />

            {/** Stripe elements */}
            <CardSection
              onCardElementUpdate={this.handleCardElementUpdate}
              cardNumErrorMsg={this.state.cardNumber.errorMsg}
              cardExpiryErrorMsg={this.state.cardExpiry.errorMsg}
              cardCvcErrorMsg={this.state.cardCvc.errorMsg}
            />

            {/** Premium card toggle */}
            <div className="is-premium-card">
              <FormControlLabel
                className="is-premium-card--switch"
                control={
                  <Switch
                    checked={this.state.isCardPremium}
                    onChange={(event) =>
                      this.setState({ isCardPremium: event.target.checked })
                    }
                  />
                }
                label={translations.isBusinessCard}
                labelPlacement="start"
              />
            </div>

            <Header noBack>Enter personal details</Header>

            {/** Email address */}
            <DataField
              isInput
              error={
                !this.state.email.isValid && this.state.email.isTouched
                  ? 'Please provide valid email'
                  : undefined
              }
              title="Email Address"
              value={this.state.email.value}
              handleChange={(event: any) => {
                this.setState({
                  email: {
                    value: event.target.value,
                    isValid: this.state.email.isValid,
                    isTouched: this.state.email.isTouched,
                  },
                });
              }}
              handleBlur={() => {
                this.setState({
                  email: {
                    value: this.state.email.value,
                    isValid: ValidationUtil.isEmailValid(
                      this.state.email.value,
                    ),
                    isTouched: true,
                  },
                });
              }}
              placeholder="name@domain.com"
            />

            {/** Address & Phone number */}
            {isAddressElementDisplayed ? (
              <AddressElement
                options={{
                  mode: 'billing',
                  autocomplete: {
                    mode: 'google_maps_api',
                    apiKey: EnvVars.PLACES_API_KEY,
                  },
                  fields: {
                    phone: isPhoneNumberFieldDisplayed ? 'always' : 'never',
                  },
                  validation: isPhoneNumberFieldDisplayed
                    ? {
                        phone: {
                          required: 'always',
                        },
                      }
                    : undefined,
                }}
                onChange={(event) => {
                  this.setState({
                    address: {
                      isComplete: event.complete,
                    },
                  });
                }}
              />
            ) : null}

            {/** Submit form button */}
            <div className="card-form__submit-btn-container">
              <Button
                disabled={isSubmitButtonDisabled}
                type="submit"
                isLoading={this.state.loading}
                label={translations.payNow}
                btnType={isSubmitButtonDisabled ? 'outline' : 'primary'}
              />
            </div>
          </form>
        </Body>
      </Container>
    );
  }
}
