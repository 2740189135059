import { FC, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { logEvent } from 'firebase/analytics';

import '../../App.scss';
import PaymentResultSuccess from '../../components/payment-result/PaymentResultSuccess';
import translations from '../../assets/translations';
import { moneyFormat } from '../../utils/Money';
import { analytics } from '../../services/FirebaseService';

interface Props {
  match: any;
  location: any;
}

const SuccessfulPaymentScreen: FC<Props> = ({ match, location }) => {
  const history = useHistory();
  const organisation = location.state.organisation;
  const transaction = location.state.transaction;

  useEffect(() => {
    logEvent(analytics, 'screen_view', {
      firebase_screen: 'Pay - payment success',
      firebase_screen_class: 'Pay - payment success',
    });
  }, []);

  return (
    <PaymentResultSuccess
      title={translations.formatString(
        translations['success.sent'],
        moneyFormat(transaction?.amount),
      )}
      text={translations['success.thanks']}
      btnText={translations['success.receipt']}
      onPress={() =>
        history.push('/pay/receipt', {
          organisation,
          transaction,
        })
      }
    />
  );
};

export default SuccessfulPaymentScreen;
