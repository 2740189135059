import { FC, memo } from 'react';

import './ReceiptConfirmContent.scss';
import translations from '../../assets/translations';
import Body from '../../widgets/layout/Body';
import Bottom from '../../widgets/layout/Bottom';
import Container from '../../widgets/layout/Container';
import Button from '../common/Button';
import BottomNavigationBar from '../navigation/BottomNavigationBar';

import Mail from '../../assets/images/ic-mail.svg';

type Props = { orgname: string; email: string; onPress: () => void };

const ReceiptConfirmContent: FC<Props> = memo(({ orgname, email, onPress }) => (
  <Container>
    <Body style={{ justifyContent: 'center' }}>
      <img style={{ alignSelf: 'center' }} width={60} src={Mail} />
      <p id="mail-text">Check your mail</p>
      <p id="mail-receipt-text">
        Your receipt from{' '}
        <span style={{ fontWeight: 'bold', color: '#FC4C02' }}>{orgname}</span>{' '}
        has been sent to <span style={{ fontWeight: 'bold' }}>{email}</span>
      </p>
    </Body>
    <Bottom>
      <Button
        btnType="outline"
        label={translations['receiptConfirm.btn']}
        onClick={onPress}
      />
    </Bottom>
    <BottomNavigationBar />
  </Container>
));

export default ReceiptConfirmContent;
