type ENV_TYPE = 'DEV' | 'STAGE' | 'PPE' | 'PROD';

type VARS_TYPE = {
  STRIPE_PK: string;
  api: string;
  local_api: string;
  RECAPTCHA: string;
  EMAIL_VERIFICATION_REDIRECT_URL: string;
  SENTRY_DSN: string;
  FIREBASE_APPCHECK_DEBUG_TOKEN?: string;
  SEGMENT_API_KEY: string;
  PLACES_API_KEY: string;
};

export const currentEnv = process.env.REACT_APP_CURRENT_ENV as ENV_TYPE;

const devVars: VARS_TYPE = {
  STRIPE_PK: 'pk_test_T1sexQraRR6Io4ZEvHOJZNxl00MkeBn3s2',
  api: 'https://europe-west2-naby-core.cloudfunctions.net/',
  local_api: 'http://192.168.31.148:5000/naby-core/europe-west2',
  RECAPTCHA: '6LcheqgdAAAAAAB9JRd9xqnM3GoSB1574uGWEkzW',
  EMAIL_VERIFICATION_REDIRECT_URL: 'https://one.nabypay.com/nKzm/c4sc9a9k',
  SENTRY_DSN:
    'https://ccbd93c7c177fd02e241ff929f078b40@o4505487529803776.ingest.sentry.io/4506297183961088',
  SEGMENT_API_KEY: 'rt448XVUMOw7vaOPe22I7AJYbkYv7Xnf',
  PLACES_API_KEY: 'AIzaSyA3U5_y37m8N8yJM_WH_qq0jeL6zBSuQUk',
};

const stageVars: VARS_TYPE = {
  STRIPE_PK: 'pk_test_T1sexQraRR6Io4ZEvHOJZNxl00MkeBn3s2',
  api: 'https://europe-west2-stage-naby-core-934ce.cloudfunctions.net/',
  local_api: 'http://192.168.31.148:5000/stage-naby-core-934ce/europe-west2',
  RECAPTCHA: '6LczaKsdAAAAADoOLSd56G4_oYFs1gSGvsvoJkWH',
  EMAIL_VERIFICATION_REDIRECT_URL: 'https://one.nabypay.com/nKzm/c4sc9a9k',
  SENTRY_DSN:
    'https://ccbd93c7c177fd02e241ff929f078b40@o4505487529803776.ingest.sentry.io/4506297183961088',
  FIREBASE_APPCHECK_DEBUG_TOKEN: '000ccee6-10d6-448c-95a7-7f65dd00e5de',
  SEGMENT_API_KEY: 'rt448XVUMOw7vaOPe22I7AJYbkYv7Xnf',
  PLACES_API_KEY: 'AIzaSyA3U5_y37m8N8yJM_WH_qq0jeL6zBSuQUk',
};

const ppeVars: VARS_TYPE = {
  STRIPE_PK:
    'pk_live_51NLMwWEnzAMYL0AroxnZkIML2VGPCJ72GeAeBYpMDa8Qwseegyk15OgsAwJMNHepPJQNqolfdNlrhOVExDQkAYTT00CHj0a07F',
  api: 'https://europe-west2-ppe-naby-core.cloudfunctions.net/',
  local_api: 'http://localhost:5001/ppe-naby-core/europe-west2',
  RECAPTCHA: '6LfvmtMmAAAAANxZfkzU5XKAqYd1Ky9vYlieDEFA',
  EMAIL_VERIFICATION_REDIRECT_URL: 'https://one.nabypay.com/CbZd/etrunxw9',
  SENTRY_DSN:
    'https://f11f452b538af07409988c968a4b7881@o4505487529803776.ingest.sentry.io/4506297185009664',
  SEGMENT_API_KEY: 'xdTytmENnEwtLpKZAQklm0pFDAzbdYwF',
  PLACES_API_KEY: 'AIzaSyDSwNq3u1biE0OUtWa2Hw3RuO5E3UtYdyg',
};

const prodVars: VARS_TYPE = {
  STRIPE_PK: 'pk_live_BfxSbEMJRyculQMX6I6tQ3uC00W1blWLzc',
  api: 'https://europe-west2-prod-naby-core.cloudfunctions.net/',
  local_api: 'http://localhost:5001/prod-naby-core/europe-west2',
  RECAPTCHA: '6LfCW7YdAAAAACuepiSSN9KO4vQXm-JQGh7II8ZE',
  EMAIL_VERIFICATION_REDIRECT_URL: 'https://one.nabypay.com/CbZd/etrunxw9',
  SENTRY_DSN:
    'https://f11f452b538af07409988c968a4b7881@o4505487529803776.ingest.sentry.io/4506297185009664',
  FIREBASE_APPCHECK_DEBUG_TOKEN: '666E9C0B-C65A-4EB9-9F34-C2046BD5D333',
  SEGMENT_API_KEY: '0j17P12J537nlVFYHiH3NNHJuFjTtkJf',
  PLACES_API_KEY: 'AIzaSyDFI0_P2MgHo8-9eX8PNU0t2XbN_98clZA',
};

export const GetEnvVars = (env: ENV_TYPE): VARS_TYPE => {
  switch (env) {
    case 'STAGE':
      return stageVars;

    case 'PPE':
      return ppeVars;

    case 'PROD':
      return prodVars;

    default:
      return devVars;
  }
};

const EnvVars = GetEnvVars(currentEnv);

if (process.env.NODE_ENV === 'development') {
  window.FIREBASE_APPCHECK_DEBUG_TOKEN = EnvVars.FIREBASE_APPCHECK_DEBUG_TOKEN;
}

export default EnvVars;
