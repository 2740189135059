import Lottie from 'lottie-react';
import { isMobile } from 'react-device-detect';
import QRCode from 'react-qr-code';

import './EmailVerification.scss';
import alertAnimation from '../../assets/lottie/alert-circle-outline.json';
import Button from '../common/Button';

export const EmailVerificationCodeExpired = ({
  onContinue,
  redirectUrl,
}: {
  onContinue: () => void;
  redirectUrl: string;
}) => {
  return (
    <div className="email-verification__content email-verification__success">
      <Lottie
        animationData={alertAnimation}
        className="email-verification__loader"
        loop={false}
      />
      <h1 className="email-verification__heading">Oops...</h1>

      <p className="email-verification__description">
        It looks like you’ve already verified your email, or it’s expired.
        Please return to the app, and if you see the verify request then repeat
        the verification steps.
      </p>

      {isMobile ? (
        <div className="email-verification__btn-container">
          <Button label="Return to app" onClick={onContinue} />
        </div>
      ) : (
        <QRCode className="email-verification__qr-code" value={redirectUrl} />
      )}
    </div>
  );
};
