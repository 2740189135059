import { getFirestore } from 'firebase/firestore';
import { FirebaseApp, deleteApp } from 'firebase/app';
import { SentryIO } from '../services/SentryIO';

/**
 * Provides services related to Firebase app management
 */
export class FirebaseUtils {
  /**
   * Checks if the specified Firebase app has been deleted based on the ability to access Firestore.
   *
   * @param {FirebaseApp} app - The Firebase app instance to check.
   * @returns {boolean} True if the app has been deleted (or cannot be used), otherwise false.
   * @throws {Error} Rethrows any error encountered except for those indicating the app cannot be used.
   */
  static checkIfAppDeleted = (app: FirebaseApp) => {
    try {
      getFirestore(app);
      return false;
    } catch (error) {
      if (error instanceof Error && error.message.includes('cannot be used')) {
        return true;
      }
      throw error;
    }
  };

  /**
   * Deletes the specified Firebase app if it has not been deleted already.
   *
   * @param {FirebaseApp} app - The Firebase app instance to delete.
   */
  static deleteApp = (app: FirebaseApp) => {
    if (!this.checkIfAppDeleted(app)) {
      deleteApp(app).catch((error) => {
        SentryIO.captureException(error, {
          extra: {
            context: 'Attempting to delete Firebase app',
          },
        });
      });
    }
  };
}
