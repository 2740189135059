import { FC, CSSProperties, ReactNode } from 'react';
import '../../App.scss';

type Props = { style?: CSSProperties; children: ReactNode; className?: string };

const Body: FC<Props> = ({ style, children, className }) => {
  return (
    <div
      style={style}
      className={className ? ['flex-body', className].join(' ') : 'flex-body'}>
      {children}
    </div>
  );
};

export default Body;
