import { PaymentMethod } from '../models/NabyAccount';

const paymentMethodsData: Record<string, PaymentMethod[]> = {
  methods: [
    {
      type: 'stripe.card',
      title: 'Card payments',
      description:
        'Accept credit & debit card payments from your customers with fees from as little as 1.6% + £0.20',
      icon_main:
        'https://firebasestorage.googleapis.com/v0/b/naby-core.appspot.com/o/app_assets%2Ficons%2Fcard_orange.png?alt=media&token=3d4b54cd-20df-4984-bc50-403704d81b6b',
      icon_alt:
        'https://firebasestorage.googleapis.com/v0/b/naby-core.appspot.com/o/app_assets%2Fpayment_methods%2Fpayment_card_white.png?alt=media&token=220db23d-cb06-4ef8-9d97-1da7467049df',
    },
    {
      type: 'stripe.bank',
      title: 'Bank account',
      description:
        'Accept payments directly into your bank account, fees are only 0.5% + 20p',
      icon_main:
        'https://firebasestorage.googleapis.com/v0/b/naby-core.appspot.com/o/app_assets%2Ficons%2Fbank_orange.png?alt=media&token=3d4b54cd-20df-4984-bc50-403704d81b6b',
      icon_alt:
        'https://firebasestorage.googleapis.com/v0/b/naby-core.appspot.com/o/app_assets%2Fpayment_methods%2Fpayment_bank_white.png?alt=media&token=220db23d-cb06-4ef8-9d97-1da7467049df',
    },
  ],
};

export const getPaymentMethods = (): PaymentMethod[] => {
  return paymentMethodsData.methods;
};
