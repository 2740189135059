import Lottie from 'lottie-react';

import './DeploymentNotificationContent.scss';
import Body from '../../widgets/layout/Body';
import Button from '../common/Button';
import Container from '../../widgets/layout/Container';
import wrenchAnimation from '../../assets/lottie/wrench.json';

export const DeploymentNotificationContent = ({
  backToLifeTime,
  onRetry,
}: {
  backToLifeTime: string;
  onRetry: () => void;
}) => {
  return (
    <Container>
      <Body className="deployment-notification">
        <div className="deployment-notification-content">
          <Lottie
            animationData={wrenchAnimation}
            className="deployment-notification-content__animation"
            loop={false}
          />
          <h1 className="deployment-notification-content__heading">
            Back soon...
          </h1>
          <p className="deployment-notification-content__text">
            While we make essential upgrades to our service, the NabyPay app
            will be temporarily unavailable.
          </p>

          {backToLifeTime ? (
            <p className="deployment-notification-content__text">
              We'll be back online at{' '}
              <span className="deployment-notification-content__text--bold">
                {backToLifeTime}
              </span>
              .
            </p>
          ) : null}
        </div>

        <Button label="Try again" btnType="secondary" onClick={onRetry} />
      </Body>
    </Container>
  );
};
