import { FC, useEffect } from 'react';
import { logEvent } from 'firebase/analytics';
import { useHistory } from 'react-router-dom';

import './styles.scss';
import '../../../components/alerts/AlertPresenter/styles.scss';
import { analytics } from '../../../services/FirebaseService';
import { AlertPresenter } from '../../../components';
import { ReactComponent as UnhappyFace } from '../../../assets/images/ic-unhappy.svg';

interface Props {
  history: any;
  location: any;
}

export const CardPaymentFailureScreen: FC<Props> = ({ location }) => {
  const history = useHistory();

  let errorMessage: string = location.state?.error ?? '';
  errorMessage = errorMessage.replace(/FirebaseError:\s?/, '');

  const userId = location.state.userId;
  const organisation = location.state.organisation;
  const transaction = location.state.transaction;
  const systemSettings = location.state.systemSettings;

  useEffect(() => {
    logEvent(analytics, 'screen_view', {
      firebase_screen: 'Pay - payment error',
      firebase_screen_class: 'Pay - payment error',
    });
  }, []);

  const handleButtonPress = () => {
    history.push('/pay/method', {
      userId,
      organisation,
      transaction,
      systemSettings,
    });
  };

  return (
    <AlertPresenter
      buttonTitle="Try again"
      onButtonPress={handleButtonPress}
      renderImage={() => <UnhappyFace className="image" />}
      title="Payment failed"
      renderMessage={() => (
        <>
          <p className="common-error__main-message">
            There was a problem taking your payment, please try again.
          </p>

          {errorMessage ? (
            <p className="common-error__main-message card-payment-failure__error-details">
              Error: {errorMessage}
            </p>
          ) : null}
        </>
      )}
    />
  );
};
