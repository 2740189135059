import axios from 'axios';
import { httpsCallable } from 'firebase/functions';

import EnvVars from '../services/EnvVars';
import { functions } from '../services/FirebaseService';
import { PaymentIntent } from '@stripe/stripe-js';

const local_api = EnvVars.local_api;
const dev_api = EnvVars.api;

export const ENVIRONMENT = dev_api;

export const FETCH_STATUS = {
  IDLE: 'idle',
  LOADING: 'loading',
  SUCCESS: 'success',
  ERROR: 'error',
};

export const apiClient = axios.create({
  baseURL: ENVIRONMENT,
  responseType: 'json',
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
    'Access-Control-Allow-Origin': '*',
  },
});

export class API {
  static sendReceiptEmail = (data: {
    orgname: string;
    email: string;
    method: string;
    amount: string;
    refNum: string;
    date: string;
    image: string;
  }) => httpsCallable(functions, 'sendEmailReceipt')(data);

  static sendNoPaymentMethodsMessage = (userId: string) =>
    httpsCallable(functions, 'sendNoPaymentMethodsError')({ userId });

  static checkUserVerificationEmail = (data: {
    code: string;
    userEmail: string;
    userId: string;
  }) =>
    httpsCallable<any, { status: number }>(
      functions,
      'checkUserVerificationEmail',
    )(data);
}
