export const getQueryParam = (
  searchParamKey: string,
  paramsString: string,
): string | undefined => {
  if (paramsString.length) {
    const searchParams = new URLSearchParams(paramsString);
    const searchEntries = searchParams.entries();
    const searchArr = Array.from(searchEntries);
    const searchReversedArr = searchArr.reverse();

    return searchReversedArr.find((entry) => entry[0] === searchParamKey)?.[1];
  }
};
