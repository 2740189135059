import Lottie from 'lottie-react';
import { isMobile } from 'react-device-detect';
import QRCode from 'react-qr-code';

import './EmailVerification.scss';
import checkAnimation from '../../assets/lottie/check-circle-outline.json';
import Button from '../common/Button';

export const EmailVerificationSuccess = ({
  onContinue,
  redirectUrl,
}: {
  onContinue: () => void;
  redirectUrl: string;
}) => {
  return (
    <div className="email-verification__content email-verification__success">
      <Lottie
        animationData={checkAnimation}
        className="email-verification__loader"
        loop={false}
        initialSegment={[0, 50]}
      />
      <h1 className="email-verification__heading">Successfully verified</h1>

      <p className="email-verification__description">
        {isMobile
          ? 'Thank you for verifying your email address, please continue to the app.'
          : 'Thank you for verifying your email address, scan the code below to continue to the app.'}
      </p>

      {/* <p className="email-verification__description">
        You can close this window and continue in the app.
      </p> */}

      {isMobile ? (
        <div className="email-verification__btn-container">
          <Button label="Continue" onClick={onContinue} />
        </div>
      ) : (
        <QRCode className="email-verification__qr-code" value={redirectUrl} />
      )}
    </div>
  );
};
