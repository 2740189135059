import { UserTraits } from '@segment/analytics-next';

import { segmentClient } from '../../../configs/segmentConfig';

/**
 * Identifies a user with given traits.
 *
 * @param {string} id - The unique identifier for the user.
 * @param {Object} [params] - Optional parameters for user traits.
 * @param {boolean} [params.isAccountBlocked] - Indicates if the user's account is blocked.
 *
 * @returns {Promise<void>} - A promise that resolves when the identification is complete.
 */
export const identifyUser = async (
  id: string,
  params?: { isAccountBlocked?: boolean },
) => {
  const traits: UserTraits = {};

  if (typeof params?.isAccountBlocked === 'boolean') {
    traits.isBlocked = params.isAccountBlocked;
  }

  await segmentClient.identify(
    id,
    Object.keys(traits).length ? traits : undefined,
  );
};
