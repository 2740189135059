import { PAGE_NOT_FOUND_REDIRECT_URL } from '../../../constants';
import { useOnMount } from '../../../hooks';
import { openInTheSameTab } from '../../../utils/utils';

export const ExternalRedirectScreen = () => {
  useOnMount(() => {
    openInTheSameTab(PAGE_NOT_FOUND_REDIRECT_URL);
  });

  return <p>Redirection...</p>;
};
