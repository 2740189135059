import './styles.scss';
import { AlertPresenter } from '../../../components';
import { ReactComponent as UnhappyFace } from '../../../assets/images/ic-unhappy.svg';
import { useState } from 'react';
import { useOnMount } from '../../../hooks';
import { openInTheSameTab } from '../../../utils/utils';

export const ErrorBoundaryScreen = () => {
  const [entryPointUrl, setEntryPointUrl] = useState<string | null>(null);

  useOnMount(() => {
    setEntryPointUrl(sessionStorage.getItem('entryPointUrl'));
  });

  return (
    <AlertPresenter
      title="Oops"
      mainMessage="There appears to have been a problem, please try again."
      renderImage={() => <UnhappyFace className="image" />}
      buttonTitle="Try again"
      onButtonPress={
        typeof entryPointUrl === 'string'
          ? () => openInTheSameTab(entryPointUrl)
          : undefined
      }
    />
  );
};
