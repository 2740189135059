import LocalizedStrings from 'react-localization';
import en from './languages/en.json';

const strings = { en };

const translations = new LocalizedStrings(strings);

const PREFERENCE_LANGUAGE = window.localStorage.getItem('preferenceLanguage');
if (PREFERENCE_LANGUAGE) {
  translations.setLanguage(PREFERENCE_LANGUAGE);
}

export default translations;
