import { useMemo } from 'react';

import { getPaymentMethods } from '../utils/paymentDataService';

const usePaymentMethods = (organisation: any, amountRestriction?: boolean) => {
  const paymentMethods = getPaymentMethods();

  const { stripeBankEnabled, stripeCardEnabled } = useMemo(() => {
    const stripeCardEnabled =
      !!organisation.paymentMethodsPublic?.['stripe.card'] &&
      !!paymentMethods.find((item) => item.type === 'stripe.card');
    const stripeBankEnabled =
      !!organisation.paymentMethodsPublic?.['stripe.bank'] &&
      !!paymentMethods.find((item) => item.type === 'stripe.bank') &&
      !amountRestriction;

    return { stripeCardEnabled, stripeBankEnabled };
  }, [organisation, amountRestriction]);

  return { stripeCardEnabled, stripeBankEnabled };
};

export { usePaymentMethods };
