import { useLocation } from 'react-router-dom';

/**
 * Custom hook for parsing query parameters from the current URL.
 *
 * @returns {URLSearchParams} An instance of URLSearchParams containing
 *                            the current URL's query parameters.
 *
 * @example
 * const query = useQuery();
 * const value = query.get('paramName'); // Replace 'paramName' with the actual query parameter key.
 */
export const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};
