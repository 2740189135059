import { memo, FC } from 'react';
import CurrencyInput from 'react-currency-input-field';
import getSymbolFromCurrency from 'currency-symbol-map';

import './DataField.scss';
import translations from '../../../assets/translations';
import { moneyFormat } from '../../../utils/Money';

type Props = {
  type?: string;
  name?: string;
  title: string;
  isAmount?: boolean;
  isInput?: boolean;
  isOptional?: boolean;
  value?: string;
  error?: string;
  handleChange?: any;
  handleBlur?: any;
  autoCapitalize?: string;
  placeholder?: string;
};

const DataField: FC<Props> = memo(
  ({
    type,
    name,
    title,
    error,
    value,
    isAmount,
    isInput,
    isOptional,
    autoCapitalize,
    handleChange,
    handleBlur,
    placeholder,
  }) => (
    <div className="data-field">
      <label
        style={{ color: '#FC4C02' }}
        className={`title ${isOptional ? 'optional' : ''}`}>
        {title + (isOptional ? translations.optional : '')}
      </label>
      {isInput && handleChange ? (
        isAmount ? (
          <CurrencyInput
            aria-invalid={!!error}
            type={type}
            name={name}
            className={`text input-amount ${!!error && 'input-invalid'}`}
            prefix={getSymbolFromCurrency('gbp')}
            allowNegativeValue={false}
            value={value}
            onValueChange={(value, _) => handleChange(value)}
            onBlur={handleBlur}
            placeholder={placeholder}
          />
        ) : (
          <input
            aria-invalid={!!error}
            type={type}
            name={name}
            className={`text input${isOptional ? '-optional' : ''} ${
              !!error && 'input-invalid'
            }`}
            value={value}
            onChange={handleChange}
            onBlur={handleBlur}
            autoCapitalize={autoCapitalize}
            placeholder={placeholder}
          />
        )
      ) : (
        <p className={`text ${isAmount ? 'amount' : ''}`}>
          {isAmount ? moneyFormat(value) : value}
        </p>
      )}
      {!!error && <p id="data-field-error">{error}</p>}
    </div>
  ),
);

export default DataField;
