import './BottomNavigationBar.scss';
import { openInNewTab } from '../../utils/utils';
import translations from '../../assets/translations';

import {
  FOOTER_LOGO_REDIRECT_URL,
  TERMS_URL,
  PRIVACY_POLICY_URL,
  COOKIE_POLICY_URL,
} from '../../constants';

const BottomNavigationBar = ({
  colorScheme = 'dark',
}: {
  colorScheme?: 'light' | 'dark';
}) => {
  return (
    <div className="amount-navbar">
      <div
        className="amount-navbar-logo"
        onClick={() => openInNewTab(FOOTER_LOGO_REDIRECT_URL)}>
        <h6
          className={[
            'amount-navbar-logo__heading',
            colorScheme === 'light' ? 'amount-navbar-logo__heading--light' : '',
          ].join(' ')}>
          {translations.poweredBy}
        </h6>
        <img
          alt="NabyPay logo"
          className="amount-navbar-logo__image"
          src={
            colorScheme === 'light'
              ? require('../../assets/images/logo_nabypay_text/logo_nabypay_text_light.png')
              : require('../../assets/images/logo_nabypay_text/logo_nabypay_text_dark.png')
          }
          srcSet={[
            colorScheme === 'light'
              ? require('../../assets/images/logo_nabypay_text/logo_nabypay_text_light@2x.png')
              : require('../../assets/images/logo_nabypay_text/logo_nabypay_text_dark@2x.png'),
            '2x,',
            colorScheme === 'light'
              ? require('../../assets/images/logo_nabypay_text/logo_nabypay_text_light@3x.png')
              : require('../../assets/images/logo_nabypay_text/logo_nabypay_text_dark@3x.png'),
            '3x',
          ].join(' ')}
        />
      </div>

      <ul className="amount-navbar-list">
        <li className="amount-navbar-list__item">
          <a
            className={[
              'amount-navbar-list__item-link',
              colorScheme === 'light'
                ? 'amount-navbar-list__item-link--light'
                : '',
            ].join(' ')}
            href={TERMS_URL}
            target="_blank">
            {translations.termsAndConditions}
          </a>
        </li>
        <li>
          <div
            className={[
              'amount-navbar-list__item-divider',
              colorScheme === 'light'
                ? 'amount-navbar-list__item-divider--light'
                : '',
            ].join(' ')}
          />
        </li>
        <li className="amount-navbar-list__item">
          <a
            className={[
              'amount-navbar-list__item-link',
              colorScheme === 'light'
                ? 'amount-navbar-list__item-link--light'
                : '',
            ].join(' ')}
            href={PRIVACY_POLICY_URL}
            target="_blank">
            {translations.privacyPolicy}
          </a>
        </li>
        <li>
          <div
            className={[
              'amount-navbar-list__item-divider',
              colorScheme === 'light'
                ? 'amount-navbar-list__item-divider--light'
                : '',
            ].join(' ')}
          />
        </li>
        <li className="amount-navbar-list__item">
          <a
            className={[
              'amount-navbar-list__item-link',
              colorScheme === 'light'
                ? 'amount-navbar-list__item-link--light'
                : '',
            ].join(' ')}
            href={COOKIE_POLICY_URL}
            target="_blank">
            {translations.cookiePolicy}
          </a>
        </li>
      </ul>
    </div>
  );
};

export default BottomNavigationBar;
