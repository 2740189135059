import React from 'react';
import QRCode from 'react-qr-code';

import Container from '../../widgets/layout/Container';
import './DesktopScreen.scss';

const DesktopScreen: React.FC = () => (
  <Container className="page">
    <div className="content">
      <h1>Continue on mobile</h1>
      <QRCode value={window.location.href} />
      <p>
        Bank transfer can only be done using a mobile device. Scan the QR code
        using the camera on your smartphone to continue
      </p>
    </div>
  </Container>
);

export default DesktopScreen;
