import { useEffect } from 'react';
import { useHistory } from 'react-router';

import { NabyOrganization } from '../../../models/NabyAccount';
import { QuickstartState } from '../../../components/Context';
import { getQueryParam } from './getQueryParam';
import { SentryIO } from '../../../services/SentryIO';

type TUseFailedPaymentEffect = {
  clientSecret?: string;
  history: ReturnType<typeof useHistory<unknown>>;
  organisation: NabyOrganization;
  queryPaymentIntentId: string;
  stripe: any;
  transaction: QuickstartState['transaction'];
  userId: string;
};

export const useFailedPaymentEffect = ({
  clientSecret,
  history,
  organisation,
  queryPaymentIntentId,
  stripe,
  transaction,
  userId,
}: TUseFailedPaymentEffect) => {
  // Function to handle redirection on error
  const redirectToFailure = (errorMsg: string) => {
    history.push('/pay/failure', {
      error: errorMsg,
      userId,
      organisation,
      transaction: {
        ...transaction,
        id: queryPaymentIntentId,
        clientSecret,
      },
    });
  };

  // Asynchronous function to handle payment intent retrieval
  const handlePaymentIntent = async () => {
    try {
      if (!clientSecret) {
        throw new Error('No payment intent client secret');
      }

      const paymentIntentRetrieved = await stripe.retrievePaymentIntent(
        clientSecret,
      );

      const errorMsg =
        paymentIntentRetrieved.paymentIntent?.last_payment_error?.message ??
        'Something went wrong!';

      redirectToFailure(errorMsg);
    } catch (error) {
      const errorMsg =
        error instanceof Error ? error.message : 'Something went wrong!';

      alert(errorMsg);

      SentryIO.captureException(error, {
        extra: {
          prompt: 'Payment by bank. Payment intent retrieving failed',
        },
      });

      redirectToFailure(errorMsg);
    }
  };

  useEffect(() => {
    const redirectStatus = getQueryParam(
      'redirect_status',
      history.location.search,
    );

    if (redirectStatus === 'failed') {
      handlePaymentIntent();
    }
  }, [history]);
};
