import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAnalytics } from 'firebase/analytics';
import { getFunctions } from 'firebase/functions';

import {
  initializeAppCheck,
  ReCaptchaEnterpriseProvider,
} from 'firebase/app-check';

import CONFIG_DEV from './firebase-config/config-dev.json';
import CONFIG_STAGE from './firebase-config/config-stage.json';
import CONFIG_PPE from './firebase-config/config-ppe.json';
import CONFIG_PROD from './firebase-config/config-prod.json';
import { currentEnv, GetEnvVars } from './EnvVars';

const getConfig = () => {
  switch (currentEnv) {
    case 'STAGE':
      return CONFIG_STAGE;

    case 'PPE':
      return CONFIG_PPE;

    case 'PROD':
      return CONFIG_PROD;

    default:
      return CONFIG_DEV;
  }
};

export const app = initializeApp(getConfig());
export const db = getFirestore(app);
export const analytics = getAnalytics(app);

export const functions = getFunctions(app, 'europe-west2');

export const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaEnterpriseProvider(GetEnvVars(currentEnv).RECAPTCHA),
  isTokenAutoRefreshEnabled: true,
});
