import { FC } from 'react';

import './HelpImprove.scss';
import Body from '../../widgets/layout/Body';
import Bottom from '../../widgets/layout/Bottom';
import Container from '../../widgets/layout/Container';
import Button from '../common/Button';
import { ReactComponent as UnhappyFace } from '../../assets/images/ic-unhappy.svg';
import translations from '../../assets/translations';

interface Props {}

const IMAGE_SIZE = 150;

const HelpImproveContent: FC<Props> = ({}) => {
  return (
    <Container>
      <Body>
        <UnhappyFace
          style={{
            width: IMAGE_SIZE,
            height: IMAGE_SIZE,
            alignSelf: 'center',
            marginLeft: 20,
          }}
        />
        <p className="helpImprove-title">{translations['feedback.title']}</p>
        <p className="helpImprove-text">{translations['feedback.text']}</p>
      </Body>
      <Bottom>
        <Button label={translations['feedback.btn']} onClick={() => {}} />
      </Bottom>
    </Container>
  );
};

export default HelpImproveContent;
