import Lottie from 'lottie-react';

import './EmailVerification.scss';
import loaderAnimation from '../../assets/lottie/loader.json';

export const EmailVerificationLoading = () => {
  return (
    <div className="email-verification__content email-verification__loading">
      <Lottie
        animationData={loaderAnimation}
        className="email-verification__loader"
        loop
      />
      <p className="email-verification__description">Verifying your email...</p>
    </div>
  );
};
