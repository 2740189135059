import { FC, memo, useEffect } from 'react';
import { useDeviceData, isAndroid, isIOS } from 'react-device-detect';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../../services/FirebaseService';

const DownloadBiz: FC = memo(() => {
  useEffect(() => {
    const deviceData = useDeviceData(window.navigator.userAgent);

    let nextLocation = 'https://nabypay.com/';
    if (isAndroid) {
      nextLocation =
        'https://play.google.com/store/apps/details?id=com.naby.nabypaybiz';
    } else if (isIOS) {
      nextLocation =
        'https://apps.apple.com/us/app/nabypay-business/id1599785729';
    }

    logEvent(analytics, 'select_content', {
      item_id: 'download_biz_app',
      content_type: `${deviceData.toString()} Result: ${window.location.href}`,
    });
    window.location.href = nextLocation;
  }, []);

  return <div></div>;
});

export default DownloadBiz;
