import { FC, memo, useRef, useEffect, useState } from 'react';
import Confetti from 'react-confetti';

import './PaymentResult.scss';
import Body from '../../widgets/layout/Body';
import Bottom from '../../widgets/layout/Bottom';
import Container from '../../widgets/layout/Container';
import Button from '../common/Button';
import { ReactComponent as Success } from '../../assets/images/ic-success.svg';
import BottomNavigationBar from '../navigation/BottomNavigationBar';

type Props = {
  isFailure?: boolean;
  title: string | string[];
  text: string;
  btnText: string;
  onPress: () => void;
};

const IMAGE_SIZE = 115;

const PaymentResultContent: FC<Props> = memo(
  ({ isFailure, title, text, btnText, onPress }) => {
    const [confettiSize, setConfettiSize] = useState<{
      height: number;
      width: number;
    }>();
    const ref = useRef<any>();
    useEffect(() => {
      setConfettiSize({
        height: ref.current ? ref.current.offsetHeight : 0,
        width: ref.current ? ref.current.offsetWidth : 0,
      });
    }, [ref.current]);

    return (
      <Container innerRef={ref} innerId="success-bg">
        {!!confettiSize && (
          <Confetti
            style={{
              marginTop: (window.innerHeight - confettiSize.height) / 2,
              marginLeft: (window.innerWidth - confettiSize.width) / 2,
            }}
            height={confettiSize.height}
            width={confettiSize.width}
            recycle={false}
            colors={['#FFFFFF', '#018745', '#99EC13']}
          />
        )}
        <Body>
          <Success
            style={{
              width: IMAGE_SIZE,
              height: IMAGE_SIZE,
              alignSelf: 'center',
              marginTop: '30%',
            }}
          />
          <p className="result-title">{title}</p>
          <p className="result-text">{text}</p>
        </Body>
        <Bottom>
          <Button
            id="email-button"
            btnType="outline"
            label={btnText}
            onClick={onPress}
          />
        </Bottom>
        <BottomNavigationBar colorScheme="light" />
      </Container>
    );
  },
);

export default PaymentResultContent;
