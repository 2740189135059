import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import '@fontsource/roboto';
import { ErrorBoundary } from 'react-error-boundary';

import './App.scss';
import DesktopScreen from './screens/desktop/DesktopScreen';
import EnterPaymentDetailsScreen from './screens/payment/EnterPaymentDetailsScreen';
import SuccessfulPaymentScreen from './screens/payment/SuccessfulPaymentScreen';
import ProvidersScreen from './screens/payment/ProvidersScreen';
import ReceiptScreen from './screens/payment/ReceiptScreen';
import { CardPaymentFailureScreen } from './screens/payment/CardPaymentFailureScreen';
import HelpImprove from './screens/payment/HelpImprove';
import ReceiptConfirmScreen from './screens/payment/ReceiptConfirmScreen';
import DownloadBiz from './screens/redirects/DownloadBiz';
import StripeSetupSuccess from './screens/StripeSetupSuccess/StripeSetupSuccess';
import EmailVerificationScreen from './screens/auth/EmailVerificationScreen/EmailVerificationScreen';

import { stripePromise } from './services/stripe/StripeService';
import { SentryIO } from './services/SentryIO';

import {
  ErrorBoundaryScreen,
  NoPaymentMethodFoundScreen,
  StripePaymentRestrictionScreen,
  PaymentMethodScreen,
  ExternalRedirectScreen,
  AccountNotFoundScreen,
  EnterPaymentAmountScreen,
  ThreeDSecureRedirectScreen,
  MerchantBlockedScreen,
} from './screens';

SentryIO.init();

const App = () => {
  // Disable removing the firebase app to check if the issue returns
  // useEffect(() => {
  //   const unloadCallback = () => {
  //     FirebaseUtils.deleteApp(app);
  //   };

  //   window.addEventListener('beforeunload', unloadCallback);

  //   return () => {
  //     window.removeEventListener('beforeunload', unloadCallback);
  //   };
  // }, []);

  return (
    <ErrorBoundary fallback={<ErrorBoundaryScreen />}>
      <Elements
        stripe={stripePromise}
        options={{
          fonts: [
            {
              cssSrc:
                'https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700;900&display=swap',
            },
          ],

          appearance: {
            theme: 'stripe',
            variables: {
              colorPrimary: '#FC4C02',
              fontFamily: 'Montserrat, system-ui, sans-serif',
              colorWarning: '#7a7a7a',
            },

            rules: {
              '.Label': {
                color: '#FC4C02',
                fontSize: '12px',
                fontWeight: '700',
                marginTop: '18px',
                marginBottom: '4px',
                textTransform: 'uppercase',
              },
              '.Input': {
                borderBottom: '1px solid #E6E6E6',
                borderLeft: 'none',
                borderRadius: '0px',
                borderRight: 'none',
                borderTop: 'none',
                boxShadow: 'none',
                color: '#7A7A7A',
                fontSize: '16px',
                fontWeight: '500',
                lineHeight: '20px',
                marginTop: '2px',
                paddingBottom: '6px',
                paddingLeft: '6px',
                paddingRight: '0px',
                paddingTop: '4px',
              },
              '.Input:focus': {
                boxShadow: 'none',
                borderBottom: '1px solid #FC4C02',
              },
              '.Input::placeholder': {
                color: '#DADADA',
              },
              '.Input--invalid': {
                boxShadow: 'none',
                borderBottom: '1px solid #a2021f',
                color: '#7A7A7A',
              },
              '.Error': {
                color: '#a2021f',
                fontSize: '12px',
                fontWeight: '500',
                lineHeight: '22px',
                marginTop: '8px',
              },
            },
          },
        }}>
        <Router>
          <Switch>
            <Route exact path="/pay" component={EnterPaymentAmountScreen} />
            <Route exact path="/pay/providers" component={ProvidersScreen} />

            <Route exact path="/pay/method" component={PaymentMethodScreen} />

            <Route
              exact
              path="/pay/confirm"
              component={EnterPaymentDetailsScreen}
            />
            <Route
              exact
              path="/pay/success"
              component={SuccessfulPaymentScreen}
            />
            <Route
              exact
              path="/pay/failure"
              component={CardPaymentFailureScreen}
            />
            <Route exact path="/pay/receipt" component={ReceiptScreen} />

            <Route
              exact
              path="/pay/merchant-offline"
              component={NoPaymentMethodFoundScreen}
            />

            <Route
              exact
              path="/pay/merchant-paused"
              component={StripePaymentRestrictionScreen}
            />

            <Route
              exact
              path="/pay/account-not-found"
              component={AccountNotFoundScreen}
            />

            {/* Merchant is blocked */}
            <Route
              exact
              path="/pay/merchant-blocked"
              component={MerchantBlockedScreen}
            />

            {/* 3D Secure Redirect Route */}
            <Route
              exact
              path="/pay/3d-secure-redirect"
              component={ThreeDSecureRedirectScreen}
            />

            <Route
              exact
              path="/receiptConfirm"
              component={ReceiptConfirmScreen}
            />
            <Route exact path="/helpImprove" component={HelpImprove} />
            <Route exact path="/pay/desktop" component={DesktopScreen} />

            <Route exact path="/download/biz" component={DownloadBiz} />

            <Route
              exact
              path="/stripe-setup-success"
              component={StripeSetupSuccess}
            />

            <Route
              exact
              path="/verify-email"
              component={EmailVerificationScreen}
            />

            <Route exact path="/redirect" component={ExternalRedirectScreen} />

            <Redirect to="/redirect" />
          </Switch>
        </Router>
      </Elements>
    </ErrorBoundary>
  );
};

export default SentryIO.withProfiler(App);
