import { loadStripe } from '@stripe/stripe-js';

import StripeApi from '../../api/StripeApi';
import EnvVars from '../EnvVars';
import { TPaymentType } from '../../models/UserMetadata';

export const stripePromise = loadStripe(EnvVars.STRIPE_PK);

export class StripeService {
  public static readonly DEFAULT_STRIPE_FIELD_PLACEHOLDER = {
    cardNumber: 'Card Number',
    cardExpiry: 'MM / YY',
    cardCvc: 'CVC',
  };
  public static readonly DEFAULT_STYLES = {
    base: {
      fontSize: '14px',
      fontFamily: 'Roboto',
      '::placeholder': {
        color: 'rgba(0,0,0,0.3)',
      },
    },
  };

  static checkToken(token: string) {
    if (!token || token === '') {
      throw new Error('Payment token required to handle payment');
    }
  }

  static checkStripeResponse(stripeResponse: any) {
    if (stripeResponse.error) {
      throw new Error(stripeResponse.error.message);
    }
  }

  static getPaymentIntentClientSecret = async (
    userId: string,
    organisationId: string,
    amount: number,
    date: number,
    refNum: string,
    currency = 'gbp',
    isAmountPrefilled?: boolean,
    paymentType?: TPaymentType,
  ) =>
    (
      await StripeApi.createPaymentIntent({
        userId,
        organisationId,
        amount,
        refNum,
        currency,
        date,
        isAmountPrefilled,
        paymentType,
      })
    ).data;
}
