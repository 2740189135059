/**
 * Use the CSS tab above to style your Element's container.
 */
import React from 'react';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from '@stripe/react-stripe-js';

import './CardSectionStyles.scss';
import translations from '../../assets/translations';

const elementOptions = {
  style: {
    base: {
      fontFamily: 'Montserrat',
      fontWeight: '500',
      fontSize: '16px',
      lineHeight: '20px',
      color: '#7a7a7a',
      '::placeholder': { color: '#dadada', fontWeight: '400' },
    },
    invalid: {
      color: '#7a7a7a',
      iconColor: '#7a7a7a',
    },
  },
};

const CardSection = ({
  onCardElementUpdate,
  cardNumErrorMsg,
  cardExpiryErrorMsg,
  cardCvcErrorMsg,
}: {
  onCardElementUpdate: ({
    elementName,
    isComplete,
    errorMsg,
  }: {
    elementName: 'cardNumber' | 'cardExpiry' | 'cardCvc';
    isComplete: boolean;
    errorMsg: string | null;
  }) => void;
  cardNumErrorMsg: string | null;
  cardExpiryErrorMsg: string | null;
  cardCvcErrorMsg: string | null;
}) => (
  <div className="card-section">
    <div className="card-number">
      <label>{translations.cardNum}</label>
      <CardNumberElement
        className="StripeInput"
        options={elementOptions}
        onChange={(event) =>
          onCardElementUpdate({
            elementName: 'cardNumber',
            isComplete: event.complete,
            errorMsg: event.error ? event.error.message : null,
          })
        }
      />
      {cardNumErrorMsg ? (
        <p className="card-element__error-msg">Provide a valid card number</p>
      ) : null}
    </div>
    <div className="horizontal-container">
      <div className="element">
        <label>{translations.expiry}</label>
        <CardExpiryElement
          className="StripeInput"
          options={elementOptions}
          onChange={(event) =>
            onCardElementUpdate({
              elementName: 'cardExpiry',
              isComplete: event.complete,
              errorMsg: event.error ? event.error.message : null,
            })
          }
        />
        {cardExpiryErrorMsg ? (
          <p className="card-element__error-msg">Provide a valid expiry</p>
        ) : null}
      </div>
      <div style={{ width: 20 }} />
      <div className="element">
        <label>CVC</label>
        <CardCvcElement
          className="StripeInput"
          options={{ placeholder: '123', ...elementOptions }}
          onChange={(event) =>
            onCardElementUpdate({
              elementName: 'cardCvc',
              isComplete: event.complete,
              errorMsg: event.error ? event.error.message : null,
            })
          }
        />
        {cardCvcErrorMsg ? (
          <p className="card-element__error-msg">Provide a valid CVC</p>
        ) : null}
      </div>
    </div>
  </div>
);

export default CardSection;
