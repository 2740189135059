import { Button as MButton } from '@mui/material';

import './Button.scss';

interface Props {
  id?: string;
  isLoading?: boolean;
  type?: 'button' | 'submit' | 'reset' | undefined;
  label: string;
  btnType?: 'primary' | 'secondary' | 'outline' | 'text';
  disabled?: boolean;
  onClick?: () => void;
  style?: React.CSSProperties;
}

const Button = ({
  isLoading,
  type,
  id,
  label,
  btnType = 'primary',
  disabled,
  onClick,
  style,
}: Props) => {
  let variant: 'text' | 'outlined' | 'contained' = 'contained';
  const generalStyle = {
    borderRadius: '10px',
    height: '60px',
    width: '100%',
    marginTop: '20px',

    fontFamily: 'Montserrat',
    fontWeight: '900',
    fontSize: '16px',
  };
  let specificStyle = {};
  switch (btnType) {
    case 'primary':
      specificStyle = {
        background: 'linear-gradient(0deg, #FC4C02, #FC4C02)', // 'linear-gradient(180deg, #FF8000 0%, #FC4C02 100%);',
      };
      break;

    case 'outline':
      variant = 'outlined';
      specificStyle = {
        background: 'transparent',
        borderColor: '#FC4C02',
        color: '#FC4C02',
        ':hover': { borderColor: '#FC4C02' },
      };
      break;

    case 'text':
      variant = 'text';
      specificStyle = {
        height: undefined,
        width: undefined,
        fontFamily: 'Montserrat',
        fontSize: '14px',
        fontWeight: '500',
        alignSelf: 'flex-end',
        color: '#FC4C02',
      };
      break;

    case 'secondary':
      variant = 'contained';
      specificStyle = {
        fontFamily: 'Montserrat',
        color: '#FC4C02',
        background: '#fff',
        ':hover': { background: '#fff' },
      };
      break;

    default:
      break;
  }

  return isLoading ? (
    <div id="loading-spinner" />
  ) : (
    <MButton
      id={id || label}
      type={type}
      className={`button--${btnType}`}
      onClick={onClick}
      variant={variant}
      disabled={disabled}
      style={style}
      sx={{
        textTransform: 'none',
        ...generalStyle,
        ...specificStyle,
      }}>
      {label}
    </MButton>
  );
};

export default Button;
