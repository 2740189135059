import { createContext, useReducer, Dispatch, ReactNode } from 'react';
import { PaymentRequest } from '@stripe/stripe-js';

import { NabyOrganization } from '../../models/NabyAccount';
import { TPaymentType } from '../../models/UserMetadata';
import { SecurePaymentThreshold } from '../../models/SystemSettings';

export interface QuickstartState {
  userId?: string;
  organisation?: NabyOrganization;
  transaction?: {
    amount: string;
    refNum: string;
    date: number;
  };
  isAmountPrefilled?: boolean;
  paymentRequest?: PaymentRequest;
  paymentType?: TPaymentType;
  systemSettings: {
    securePaymentThreshold?: SecurePaymentThreshold;
  };
}

const initialState: QuickstartState = {
  systemSettings: {
    securePaymentThreshold: undefined,
  },
};

export enum ActionTypes {
  setState = 'SET_STATE',
  setSecurePaymentThreshold = 'SET_SECURE_PAYMENT_THRESHOLD',
}

type QuickstartAction = {
  type: ActionTypes.setState;
  state: Partial<QuickstartState>;
};

type SetSecurePaymentThreshold = {
  type: ActionTypes.setSecurePaymentThreshold;
  state: {
    securePaymentThreshold: SecurePaymentThreshold;
  };
};

interface QuickstartContext extends QuickstartState {
  dispatch: Dispatch<QuickstartAction | SetSecurePaymentThreshold>;
}

const Context = createContext<QuickstartContext>(
  initialState as QuickstartContext,
);

const { Provider } = Context;
export const AppProvider: React.FC<{ children: ReactNode }> = (props) => {
  const reducer = (
    state: QuickstartState,
    action: QuickstartAction | SetSecurePaymentThreshold,
  ): QuickstartState => {
    switch (action.type) {
      case ActionTypes.setState:
        return { ...state, ...action.state };
      case ActionTypes.setSecurePaymentThreshold:
        return {
          ...state,
          systemSettings: {
            ...state.systemSettings,
            securePaymentThreshold: action.state.securePaymentThreshold,
          },
        };
      default:
        return { ...state };
    }
  };
  const [state, dispatch] = useReducer(reducer, initialState);
  return <Provider value={{ ...state, dispatch }}>{props.children}</Provider>;
};

export default Context;
