import * as Sentry from '@sentry/react';
import { isSafari, isDesktop } from 'react-device-detect';

import EnvVars from './EnvVars';
import packageJson from '../../package.json';

const integrations: Sentry.BrowserOptions['integrations'] = [
  new Sentry.BrowserTracing({
    tracePropagationTargets: [
      'localhost',
      /^https:\/\/(app|ppe-app|stage-app|dev-app)\.nabypay\.com/,
    ],
  }),
  new Sentry.Replay(),
];

if (isSafari && isDesktop) {
  integrations.pop();
}

export class SentryIO {
  static init() {
    Sentry.init({
      release: [packageJson.name, '@', packageJson.version].join(''),
      dsn: EnvVars.SENTRY_DSN,
      integrations,
      tracesSampleRate: 1.0,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
      autoSessionTracking: true,
    });
  }

  static withProfiler = Sentry.withProfiler;
  static setUser = Sentry.setUser;
  static setExtra = Sentry.setExtra;
  static captureException = Sentry.captureException;
}
