import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import './EmailVerificationScreen.scss';
import Body from '../../../widgets/layout/Body';
import BottomNavigationBar from '../../../components/navigation/BottomNavigationBar';
import Container from '../../../widgets/layout/Container';
import EnvVars from '../../../services/EnvVars';
import { API, FETCH_STATUS } from '../../../api/ApiClient';
import { EmailVerificationCodeExpired } from '../../../components/email-verification/EmailVerificationCodeExpired';
import { EmailVerificationError } from '../../../components/email-verification/EmailVerificationError';
import { EmailVerificationLoading } from '../../../components/email-verification/EmailVerificationLoading';
import { EmailVerificationSuccess } from '../../../components/email-verification/EmailVerificationSuccess';
import { getDeploymentSettings } from '../../../services/FirestoreService';
import { openInNewTab } from '../../../utils/utils';
import { DeploymentNotificationContent } from '../../../components/maintenance/DeploymentNotificationContent';
import { SentryIO } from '../../../services/SentryIO';

const useQuery = () => new URLSearchParams(useLocation().search);
const redirectUrl = EnvVars.EMAIL_VERIFICATION_REDIRECT_URL;
const CONTACT_US_URL = 'https://www.nabypay.com/contact';

const VERIFICATION_FETCH_STATUS = {
  ...FETCH_STATUS,
  CODE_EXPIRED: 'code_expired',
  DEPLOYMENT_ENABLED: 'deployment_enabled',
};

const EmailVerificationScreen = () => {
  const [status, setStatus] = useState(VERIFICATION_FETCH_STATUS.IDLE);
  const query = useQuery();

  const code = query.get('code');
  const userEmail = query.get('le');
  const userId = query.get('de');

  const [deploymentSettings, setDeploymentSettings] = useState<{
    backToLifeTime: string;
    enabled: boolean;
  }>();

  const handleVerifyEmail = useCallback(() => {
    (async () => {
      setStatus(VERIFICATION_FETCH_STATUS.LOADING);

      try {
        const deploymentSettingsResponse = await getDeploymentSettings();

        if (
          typeof deploymentSettingsResponse.data.backToLifeTime === 'string' &&
          typeof deploymentSettingsResponse.data.enabled === 'boolean'
        ) {
          setDeploymentSettings({
            enabled: deploymentSettingsResponse.data.enabled,
            backToLifeTime: deploymentSettingsResponse.data.backToLifeTime,
          });
        }

        if (
          (status === VERIFICATION_FETCH_STATUS.IDLE ||
            status === VERIFICATION_FETCH_STATUS.ERROR ||
            status === VERIFICATION_FETCH_STATUS.DEPLOYMENT_ENABLED) &&
          code &&
          userEmail &&
          userId &&
          deploymentSettingsResponse.data.enabled === false
        ) {
          const verificationResponse = await API.checkUserVerificationEmail({
            code,
            userEmail,
            userId,
          });

          if (verificationResponse.data.status === 404) {
            setStatus(VERIFICATION_FETCH_STATUS.CODE_EXPIRED);
          } else {
            setStatus(VERIFICATION_FETCH_STATUS.SUCCESS);
          }
        } else if (deploymentSettingsResponse.data.enabled === true) {
          setStatus(VERIFICATION_FETCH_STATUS.DEPLOYMENT_ENABLED);
        } else {
          setStatus(VERIFICATION_FETCH_STATUS.ERROR);
        }
      } catch (error) {
        setStatus(VERIFICATION_FETCH_STATUS.ERROR);
        SentryIO.captureException(error, {
          extra: {
            prompt: 'Email verification failed',
          },
        });
      }
    })();
  }, [status, code, userEmail, userId]);

  useEffect(() => {
    if (status === VERIFICATION_FETCH_STATUS.IDLE) {
      handleVerifyEmail();
    }
  }, [handleVerifyEmail, status]);

  const handleContinue = () => window.open(redirectUrl);
  const handleContactUs = () => openInNewTab(CONTACT_US_URL);

  const componentMap = {
    [VERIFICATION_FETCH_STATUS.LOADING]: <EmailVerificationLoading />,
    [VERIFICATION_FETCH_STATUS.ERROR]: (
      <EmailVerificationError
        onVerifyEmail={handleVerifyEmail}
        onContactUs={handleContactUs}
      />
    ),
    [VERIFICATION_FETCH_STATUS.CODE_EXPIRED]: (
      <EmailVerificationCodeExpired
        onContinue={handleContinue}
        redirectUrl={redirectUrl}
      />
    ),
    [VERIFICATION_FETCH_STATUS.SUCCESS]: (
      <EmailVerificationSuccess
        onContinue={handleContinue}
        redirectUrl={redirectUrl}
      />
    ),
    unknownStatus: <div>Unknown Status</div>,
  };

  if (
    status === VERIFICATION_FETCH_STATUS.DEPLOYMENT_ENABLED &&
    deploymentSettings?.backToLifeTime
  ) {
    return (
      <DeploymentNotificationContent
        backToLifeTime={deploymentSettings.backToLifeTime}
        onRetry={handleVerifyEmail}
      />
    );
  }

  return (
    <Container>
      <Body style={{ paddingBottom: 0 }}>
        {componentMap[status] || componentMap.unknownStatus}
      </Body>
      <BottomNavigationBar />
    </Container>
  );
};

export default EmailVerificationScreen;
