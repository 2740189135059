import './CompanyInfo.scss';

type TCompanyInfoProps = {
  companyName: string;
  companyAddress: string;
  companyZip: string;
  companyRegNum?: string;
};

const CompanyInfo = ({
  companyName,
  companyAddress,
  companyZip,
  companyRegNum,
}: TCompanyInfoProps) => {
  return (
    <>
      {companyName ? <p className="company-info">{companyName}</p> : null}
      {companyAddress && companyZip ? (
        <p className="company-info">{`${companyAddress}, ${companyZip}`}</p>
      ) : null}
      {companyRegNum ? (
        <p className="company-info">Reg: {companyRegNum}</p>
      ) : null}
    </>
  );
};

export default CompanyInfo;
