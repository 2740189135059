import Body from '../../widgets/layout/Body';
import Container from '../../widgets/layout/Container';
import './StripeSetupSuccess.scss';

const StripeSetupSuccess = () => {
  return (
    <Container>
      <Body>
        <div className="stripe-setup-success">
          <div className="stripe-setup-success__loading-spinner" />
          <h1 className="stripe-setup-success__loading-text">Processing...</h1>
        </div>
      </Body>
    </Container>
  );
};

export default StripeSetupSuccess;
